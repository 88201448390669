import {onBeforeMount, reactive, toRefs} from '@vue/composition-api'
import ExtraService from "@/services/extra/extra.service";


export function useExtraDiscountPolicyTypes() {
    const data = reactive({
        discountPolicyTypes: null,
    })

    const fetchDiscountPolicyTypes =  async () => {
        const response = await ExtraService.discountPolicyTypes();
        data.discountPolicyTypes = response.data.items;
    };

    const getTranslationByTitle = (title) => {
        switch (title) {
            case 'fixed':
                return 'Фiксована';
            case 'absolute_by_price':
                return 'Абсолютна за цiною';
            default:
                return title;
        }
    }

    const getDiscountPolicyTypeTitle = (id) => {
        let title = data.discountPolicyTypes?.find(el => el.id === id)?.title;
        if (title) {
            return getTranslationByTitle(title);
        }

        return title;
    }

    onBeforeMount(async() => {
        await fetchDiscountPolicyTypes();
    });

    return {getDiscountPolicyTypeTitle, ...toRefs(data)};

}