import {onBeforeMount, reactive, toRefs} from '@vue/composition-api'
import ExtraService from "@/services/extra/extra.service";


export function useExtraIssuers() {
    const data = reactive({
        issuers: null,
    })

    const fetchIssuers =  async () => {
        const response = await ExtraService.issuers();
        data.issuers = response.data.items;
    };

    const getIssuerTitle = (id) => {
        return data.issuers?.find(el => el.id === id)?.title;
    }

    const getIssuerImage = (id) => {
        return data.issuers?.find(el => el.id === id)?.imageId;
    }

    onBeforeMount(async() => {
        await fetchIssuers();
    });

    return {getIssuerTitle, getIssuerImage, ...toRefs(data)};

}