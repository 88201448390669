<template>
  <div>
    <b-card>
      <b-card-title class="mb-1">{{ discountTemplate.title }}</b-card-title>
        <b-table
          v-bind="getTableAttributes"
          :items="tableItems"
          :fields="newTableColumns"
          class="text-center"
        >
          <template #cell(issuer)="data">
            <div class="text-left custom-media-cell">
                <b-avatar
                  :id="`fuel-row-${data.item.issuer.issuerImage}`"
                  size="32"
                  :src="data.item.issuerImage ? `https://app.monobrand.club/images/company/${data.item.issuerImage}/logo.png` : null"
                  :text="getIssuerTitle(data.item.issuer)"
                />
                <span
                  class="px-1"
                >
                  {{ getIssuerTitle(data.item.issuer) }}
                </span>
            </div>
          </template>
        </b-table>
    </b-card>
  </div>
</template>

<script>

import { ref } from 'vue';
import {BCard, BCardText, BCardTitle, BCardSubTitle} from "bootstrap-vue/src/components/card";
import {BTable, BAvatar, BMedia} from "bootstrap-vue";
import {useExtraIssuers} from "@/composition/extra/useExtraIssuers";

import {useExtraDiscountPolicyTypes} from "@/composition/extra/useDisountsPolicyTypes";
import {computed, onBeforeMount} from "@vue/composition-api";
import {useRouter} from "@core/utils/utils";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import { useFuels } from "@/composition/extra/useFuels";

export default {
  name: "DiscountView",
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BAvatar,
    BMedia,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    }
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  computed: {
    getTableAttributes() {
      return this.screenWidth < 768 ? { stacked: true } : { small: true };
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  setup() {
    const {route} = useRouter();
    const {getIssuerTitle, getIssuerImage} = useExtraIssuers();
    const {getDiscountPolicyTypeTitle} = useExtraDiscountPolicyTypes();
    const { fetchDiscountTemplates, discountTemplates } = useDiscountTemplatesFetch();
    const { getFuelTitleShort, fuels } = useFuels();

    const discountTemplate = computed(() => {
      return discountTemplates?.value?.find(template => parseInt(template.id) === parseInt(route.value.params.id));
    });

    onBeforeMount(async () => {
      await fetchDiscountTemplates();
    });

      const tableColumns = [
        { key: "issuer", label: "АЗС" },
        { key: "discountPolicy", label: "Політика знижки" },
      ];

      const newTableColumns = computed(() => {
        const columns = [...tableColumns];
        const fuelsArray = fuels.value;

        for (const item of fuelsArray) {
          columns.push({ key: item.value, label: getFuelTitleShort(item.title) });
        }

        return columns;
      });

      const FUEL_TYPES = ["dp", "dpplus", "92", "95", "95plus", "98", "100", "lpg"];

    const tableItems = computed(() => {
      const items = [];

      for (const issuer of discountTemplate.value.issuers) {
        if (!issuer.discounts.length) continue;

        const item = {
          issuer: issuer.id,
          issuerImage: getIssuerImage(issuer.id),
          discountPolicy: getDiscountPolicyTypeTitle(issuer.discounts[0].typeDiscountPolicyId),
        };

        /*for (const discount of issuer.discounts) {
          item[discount.fuelType] = discount.value;
        }*/

          const discountsByFuelType = issuer.discounts.reduce((acc, discount) => {
            acc[discount.fuelType] = discount.value.toFixed(2);
            return acc;
          }, {});

          for (const fuelType of FUEL_TYPES) {
            item[fuelType] = discountsByFuelType[fuelType] || "-";
          }

        items.push(item);
      }

      return items;
    });

    return {discountTemplate, getDiscountPolicyTypeTitle, getIssuerTitle, getIssuerImage, newTableColumns, tableItems, getFuelsTitle: getFuelTitleShort}
  },
};
</script>
<style type="scss">
  @media screen and (max-width: 768px) {
    .custom-media-cell {
      display: flex;
      flex-direction: row;
    }
}
</style>